import 'lib/mustache';
import 'sbf-design-system-component/header/header';
import 'sbf-design-system-component/overlays/overlay';
import 'modules/compose-linker';
import 'lib/jquery-ujs';
import 'sbf-design-system-component/forms/base';

$('#secondaryNav').on('change', function (event) {
  if ($(this).val()) {
    const $option = $(this).find(':selected');
    if ($option.val() !== 'Select a Dashboard') {
      window.location = $option.val();
    }
  }
});
