/*
    This script enables prefilling of recipients on the compose page.

    This script requires an authenticity token in the ajaxBaseData to work

    Add `data-compose-recipients` attribute to a link or a button, and this
    script will transparently turn it into a form post. Optionally add the
    `data-compose-subject` attribute to set the message subject.

    `data-compose-recipients` must be a JSON string, containing an array of
    objects with first, last, and profile_id properties.

    Example HTML:

        <a href="/communicate/compose"
           data-compose-recipients='[{
              "display_name": "Matt Juhl",
              "profile_id": 123
           },{
               "display_name": "Bryon Stuefer",
               "profile_id": 456
           },
           {
               "display_name": "Timothy Miller",
               "profile_id": 789
           }]'
           data-compose-subject="This is optional">
           data-compose-subject="This is optional"
           data-compose-body="This is optional and should be url encoded"
           data-compose-reply-to-id="original_message_id_goes_here">
            Link Text Here
        </a>

    */
$(function () {
  $(document).on('click', '[data-compose-recipients]', function (click) {
    click.preventDefault();

    if ($(this).hasClass('disabled')) {
      return false;
    }

    let body = document.getElementsByTagName('body')[0];
    const form = document.createElement('form');
    form.action = '/communicate/compose';
    form.method = 'POST';
    body.appendChild(form);

    const recipientsJsonInput = document.createElement('input');
    recipientsJsonInput.type = 'hidden';
    recipientsJsonInput.name = 'recipients_json';
    recipientsJsonInput.value = this.getAttribute('data-compose-recipients');
    form.appendChild(recipientsJsonInput);

    const subject = this.getAttribute('data-compose-subject');

    if (subject) {
      const subjectInput = document.createElement('input');
      subjectInput.type = 'hidden';
      subjectInput.name = 'subject';
      subjectInput.value = subject;
      form.appendChild(subjectInput);
    }

    body = this.getAttribute('data-compose-body');

    if (body) {
      const bodyInput = document.createElement('input');
      bodyInput.type = 'hidden';
      bodyInput.name = 'body';
      bodyInput.value = body;
      form.appendChild(bodyInput);
    }

    const replyToId = this.getAttribute('data-compose-reply-to-id');

    if (replyToId) {
      const replyToIdInput = document.createElement('input');
      replyToIdInput.type = 'hidden';
      replyToIdInput.name = 'replyToId';
      replyToIdInput.value = replyToId;
      form.appendChild(replyToIdInput);
    }

    let input, key;
    for (key in window.ajaxBaseData) {
      input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = window.ajaxBaseData[key];
      form.appendChild(input);
    }
    form.submit();
  });
});
